.animated-border {
    border-bottom: 2px solid black;
    transition: border-bottom 0.2s ease-in-out;
}

.non-animated-border {
    border-bottom: 0 solid transparent;
    transition: border-bottom 0.2s ease-in-out;
}

.tab-container {
    position: relative;
}

.positioned-divider {
    position: absolute;
    width: 100%;
    bottom: 0;
}